import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const IssueService = {
    SearchTransaction(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/issue/transaction/search/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async Issue(transaction_id: string) {
        return await $axios.post('/admin/issue/orders/by/transaction/', {transaction_id: transaction_id})
    },
}