import React, {useEffect, useState} from "react";
import {
    CustomInput, CustomPageSizeInput,
    CustomTable, FilterSearchIcon, RollBackIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../helpers/helpers";
import {ShipmentService} from "../services/ShipmentService";
import {useNavigate, useParams} from "react-router-dom";
import {BoxOfficeService} from "../services/BoxOfficeService";

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

export default function BoxOfficeDetails() {
    const {id} = useParams()
    const navigate = useNavigate();

    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'tracking_number', headerName: 'Трек-номер', width: 150},
                {field: 'title', headerName: 'Наименование', width: 120},
                {
                    field: 'type_of_transport', headerName: 'Транспорт', width: 120, renderCell: (params: any) => (
                        <img src={params.type_of_transport.icon} alt="icon"/>
                    )
                },
                {
                    field: 'status', headerName: 'Статус', width: 120, renderCell: (params: any) => (
                        params.status && params.status.svg_script ? (
                            <div dangerouslySetInnerHTML={{ __html: params.status.svg_script }} />
                        ) : (
                            <div>No Status Available</div>
                        )
                    )
                },
            ]
    })

    const groupInfo = BoxOfficeService.GetGroup(id)

    const orderList = BoxOfficeService.GetGroupOrders(table.filter,id)


    useEffect(()=>{
        if(orderList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(orderList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: orderList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = orderList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[orderList.loading, orderList.error])

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Детальный просмотр счета</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-2'>
                    <div className='page__rollBack-icon' onClick={()=>{navigate(-1)}}>
                        <div>
                            <RollBackIcon/>
                        </div>
                        <p>Назад</p>
                    </div>
                </div>
            </div>

            <div className='flex w-full flex-col justify-start items-start bg-white rounded-2xl p-5 mb-12'>
                {!groupInfo.loading && !groupInfo.error
                    ?
                    <div className='flex flex-col justify-start items-start w-full gap-4'>
                        <p><b>ФИО</b>: <span>{groupInfo.result?.data.user.full_name}</span></p>
                        <p><b>Код клиента</b>: <span>{groupInfo.result?.data.user.code}</span></p>
                        <p><b>Общий вес</b>: <span>{groupInfo.result?.data.total_weight} кг</span></p>
                        <p><b>Количество посылок</b>: <span>{groupInfo.result?.data.orders_count}</span></p>
                    </div>
                    :
                    <div className='flex flex-col justify-start items-start w-full gap-4'>
                        <p><b>ФИО</b>: <span>...</span></p>
                        <p><b>Код клиента</b>: <span>...</span></p>
                        <p><b>Общий вес</b>: <span>...</span></p>
                        <p><b>Количество посылок</b>: <span>...</span></p>
                    </div>
                }
            </div>

            <div>
                <h1 className='status__title mb-5'>Посылки</h1>
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10 '>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    selectedRowsId={table.selectedRows}
                    onSelectionModelChange={(selectionModel)=>setTable({
                        ...table,
                        selectedRows: selectionModel,
                    })}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}