import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams, RemoveEmptyObj} from "../helpers/helpers";
import {StaffUpdateI} from "../models/StaffI";

export const StaffService = {
    GetStaffList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/staffers/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async UpdateStaff(staff: StaffUpdateI) {
        return await $axios.put(`/admin/staffer/${staff.id}/`,RemoveEmptyObj(staff))
    },
    async CreateStaff(staff: StaffUpdateI) {
        const staffInfoToCreate = {
            country: staff.country,
            full_name: staff.user?.full_name,
            group: staff.user.group,
            email: staff.user.email,
            password: staff.user.password,
            confirm_password: staff.user.confirm_password,
        }
        return await $axios.post('/admin/staffer/create/', staffInfoToCreate)
    },
    async DeleteStaff(staff: StaffUpdateI) {
        return await $axios.delete('/admin/staffer/' + `${staff.id}/`)
    }
}