import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const DeliveriesService = {
    GetGroupsList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/active/deliveries/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async SubmitDelivery(delivery_id: string) {
        return await $axios.put('/admin/active/deliveries/' + `${delivery_id}/`,{
            issued: true
        } )
    },
}