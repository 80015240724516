import React, {useState} from "react";
import {CustomInput, PasswordHiddenIcon, PasswordVisibleIcon} from "../helpers/common";
import {AuthService} from "../services/AuthService";
import {useDispatch} from "react-redux";
import {login} from "../store/slices/userSlice";

const modalInitialValues = {
    values: {
        email: '',
        password: '',
    },
    validation: {
        error: {
            email: false,
            password: false,
        },
        message: {
            email: '',
            password: '',
        }
    },
    passwordVisible:false,
    requestIsSend: false,
}
function AuthPage(){
    const [modal, setModal] = useState(modalInitialValues)
    const dispatch = useDispatch()

    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        setModal({
            ...modal,
            requestIsSend: true,
        })
        AuthService.GetToken(modal.values.email, modal.values.password).then((res)=>{
            dispatch(login(res))
        }).catch((err)=>{
            if(err.response.data.detail){
                setModal({
                    ...modal,
                    validation: {
                        ...modal.validation,
                        error: {
                            ...modal.validation.error,
                            email: true,
                            password: true,
                        },
                        message: {
                            ...modal.validation.message,
                            email: err.response.data.detail,
                            password: err.response.data.detail,
                        }
                    },
                    requestIsSend: false
                })
            }
        })
    }
    return (
        <div className='flex justify-center items-center w-full min-h-screen h-screen p-5'>
            <form onSubmit={handleSubmit} className='flex flex-col justify-start items-center py-60px px-163px bg-white rounded-10px shadow-default max-w-auth-width w-full'>
                <h1 className="auth__title">Авторизация</h1>
                <CustomInput
                    className='text-input'
                    type='email'
                    placeholder='Email'
                    required={true}
                    label='Email'
                    value={modal.values.email}
                    onChange={(event)=>{
                        setModal({
                            ...modal,
                            values: {
                                ...modal.values,
                                email: event.target.value
                            }
                        })
                    }}
                    helperText={modal.validation.message.email}
                    error={modal.validation.error.email}
                />
                <CustomInput
                    className='text-input text-input_with-props'
                    type={modal.passwordVisible ? 'text' : 'password'}
                    placeholder='Пароль'
                    label='Пароль'
                    value={modal.values.password}
                    helperText={modal.validation.message.password}
                    error={modal.validation.error.password}
                    required={true}
                    onChange={(event)=>{
                        setModal({
                            ...modal,
                            values: {
                                ...modal.values,
                                password: event.target.value
                            }
                        })
                    }}
                    inputProps={
                        <div className='text-input_icon_button' onClick={()=>{
                            setModal({
                                ...modal,
                                passwordVisible: !modal.passwordVisible,
                            })
                        }}>
                            {modal.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                        </div>
                    }
                />
                <button disabled={modal.requestIsSend} type='submit' className='submit-button_orange h-12 w-full mt-4'>
                    {modal.requestIsSend
                        ?<div className="loader"></div>
                        :'Войти'
                    }
                </button>
            </form>
        </div>
    );
}

export default AuthPage;