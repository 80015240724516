import React, {useEffect, useState} from "react";
import {CustomForm, CustomInput, CustomPageSizeInput, CustomTable, FilterSearchIcon,} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../helpers/helpers";
import {SortingService} from "../services/SortingService";
import {LostOrdersService} from "../services/LostOrdersService";
import {useNavigate} from "react-router-dom";

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}
const modalInitialValues = {
    values: {
        code: '',
        full_name: '',
    },
    validation: {
        error: {
            code: false,
            full_name: false,
        },
        message: {
            code: '',
            full_name: '',
        }
    },
    type: '',
    requestIsSend: false,
    open: false,
}

export default function LostOrders() {
    const navigate = useNavigate()
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {
                    field: 'code', headerName: 'Код клиента', width: 100, renderCell: (params: any) => (
                        params.user?.code
                    )
                },
                {
                    field: 'full_name', headerName: 'ФИО', width: 150, renderCell: (params: any) => (
                        params.user?.full_name
                    )
                },
                {field: 'tracking_number', headerName: 'Трек-номер', width: 150},
            ]
    })

    const [modal, setModal] = useState(modalInitialValues)

    const tableList = LostOrdersService.GetOrderList(table.filter)


    useEffect(()=>{
        if(tableList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(tableList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = tableList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[tableList.loading, tableList.error])

    const submitModalForm = ()=>{
        setModal({
            ...modal,
            requestIsSend: true,
        })
        LostOrdersService.AssignOrders({code: modal.values.code, orders: table.selectedRows}).then(()=>{
            setModal(modalInitialValues)
            tableList.execute()
        })
    }

    const customer = SortingService.SearchClient({search: modal.values.code})

    useEffect(()=>{
        if(!customer.loading && !customer.error){
            if(customer.result?.data.length > 0){
                setModal({
                    ...modal,
                    values: {
                        ...modal.values,
                        full_name: customer.result?.data[0].full_name
                    }
                })
            }
            else {
                setModal({
                    ...modal,
                    values: {
                        ...modal.values,
                        full_name: ''
                    }
                })
            }
        }
    },[customer.loading, customer.error])

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Неизвестные посылки</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event)=>{setTable({
                        ...table,
                        selectedRows: [],
                        filter:{
                            ...table.filter,
                            search: event.target.value,
                            page: 1,
                        }
                    })}}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={true}
                    selectedRowsId={table.selectedRows}
                    onSelectionModelChange={(selectionModel)=>setTable({
                        ...table,
                        selectedRows: selectionModel,
                    })}
                    onRowDoubleClick={(params)=>navigate(`/orders/${params.id}`)}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <button className='submit-button_orange flex-shrink-0 submit-button_blue h-10'
                                    disabled={table.selectedRows.length === 0}
                                    onClick={()=>{
                                        setModal({...modal, open: true})
                                    }}
                            >
                                Привязать посылки
                            </button>
                        </div>
                    }
                />
            </div>

            {modal.open &&
                <CustomForm
                    title={'Привязать посылки'}
                    style={{maxWidth: '844px', width:'100%'}}
                    buttonText={'Привязать'}
                    cancelButton={true}
                    cancelButtonText='Отмена'
                    onClose={()=>{
                        setModal(modalInitialValues)
                    }}
                    onSubmit={()=>{
                        submitModalForm()
                    }}
                    requestIsSend={modal.requestIsSend}
                    disabled={modal.values.full_name === ''}
                    children={
                        <>
                            <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'ФИО'}
                                    required={true}
                                    label={'ФИО'}
                                    disabled={true}
                                    value={modal.values.full_name}
                                    helperText={modal.validation.message.full_name}
                                    error={modal.validation.error.full_name}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='number'
                                    placeholder={'Код клиента'}
                                    required={true}
                                    label={'Код клиента'}
                                    helperText={modal.validation.message.code}
                                    error={modal.validation.error.code}
                                    value={modal.values.code}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                code: event.target.value,
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }
                />
            }
        </div>
    )
}