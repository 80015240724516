import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const TransportsService = {
    GetTransportsList(searchParams?: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/transports/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
}