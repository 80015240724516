import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput,
    StatisticsBoxOfficeIcon,
    StatisticsOrderIcon,
    StatisticsUserIcon
} from "../helpers/common";
import moment from "moment";
import {AnalyticsService} from "../services/AnalyticsService";
import {CurrencyService} from "../services/CurrencyService";
import {Line} from "react-chartjs-2";
import {DateRangePicker} from "rsuite";
import {endOfDay, startOfDay, subDays} from "date-fns";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {InputCheckForNumbers} from "../helpers/helpers";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
function HomePageForOthers() {
    return(
        <div className='componentMainWrapper'>
            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Главная</h1>
            </div>
        </div>
    )
}
export default HomePageForOthers