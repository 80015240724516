import React, {useEffect, useState} from "react";
import {
    CustomForm, CustomInput, CustomPageSizeInput, CustomSelect,
    CustomTable, FilterSearchIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers, checkModalResponse} from "../helpers/helpers";
import {BoxOfficeService} from "../services/BoxOfficeService";
import queueIcon from '../assets/images/icons8-счет-24.png'
import {useNavigate} from "react-router-dom";


const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

const modalInitialValues = {
    values: {
        issue_id: '',
        price_in_dollars: '',
        price_in_soms: '',
        payment: '',
        discount: '0',
        for_delivery: false,
    },
    validation: {
        error: {
            issue_id: false,
            price_in_dollars: false,
            price_in_soms: false,
            payment: false,
            discount: false,
            for_delivery: false,
        },
        message: {
            issue_id: '',
            price_in_dollars: '',
            price_in_soms: '',
            payment: '',
            discount: '',
            for_delivery: '',
        }
    },
    requestIsSend: false,
    open: false,
}

export default function BoxOffice() {
    const navigate = useNavigate()

    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {
                    field: 'code', headerName: 'Код клиента', width: 100, renderCell: (params: any) => (
                        params.user?.code
                    )
                },
                {
                    field: 'full_name', headerName: 'ФИО', width: 150, renderCell: (params: any) => (
                        params.user?.full_name
                    )
                },
                {field: 'orders_count', headerName: 'Количество', width: 150},
                {field: 'total_weight', headerName: 'Вес', width: 80},
                {
                    field: 'action', headerName: 'Выставление счета', width: 80, renderCell: (params: any) => (
                        <button className='boxOffice__button' title='Выставить счет' onClick={() => {
                            handleCalculationOfOrders(params.id)
                        }}>
                            <img src={queueIcon} alt="icon"/>
                        </button>
                    )
                },
            ]
    })
    const [modal, setModal] = useState(modalInitialValues)

    const tableList = BoxOfficeService.GetGroupsList(table.filter)
    const payments = BoxOfficeService.GetPaymentList()

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                loading: true,
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        } else {
            const data = tableList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    }, [tableList.loading, tableList.error])

    const handleCalculationOfOrders = (cashOrderId: string) => {
        setModal({...modal, open: true})
        BoxOfficeService.CalculateCashOrders(cashOrderId).then((res) => {
            setModal({
                ...modal,
                values: {
                    ...modal.values,
                    price_in_dollars: `${res.data.price_in_dollars}`,
                    price_in_soms: `${res.data.price_in_soms}`,
                    issue_id: cashOrderId,
                },
                open: true,
            })
        }).catch(() => {
            setModal(modalInitialValues)
        })
    }

    const submitModal = () => {
        setModal({
            ...modal,
            requestIsSend: true,
        })
        BoxOfficeService.CreateTransaction({
            payment_method: modal.values.payment,
            discount: modal.values.discount,
            orders_group: modal.values.issue_id,
            for_delivery: modal.values.for_delivery
        }).then((res) => {
            window.open(res.data.cheque)
            setModal(modalInitialValues)
            tableList.execute()
        }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
    }


    return (
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Касса</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event) => {
                        setTable({
                            ...table,
                            selectedRows: [],
                            filter: {
                                ...table.filter,
                                search: event.target.value,
                                page: 1,
                            }
                        })
                    }}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    onRowDoubleClick={(params: any) => navigate(`/boxOffice/${params.id}`)}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event, value) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>

            {modal.open &&
                <CustomForm
                    title={'Выставить счет'}
                    style={{maxWidth: '844px', width: '100%'}}
                    buttonText={'Выставить'}
                    cancelButton={true}
                    cancelButtonText='Отмена'
                    onClose={() => {setModal(modalInitialValues)}}
                    onSubmit={() => {submitModal()}}
                    requestIsSend={modal.requestIsSend}
                    disabled={modal.values.issue_id === ''}
                    children={
                        <>
                            <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                                <div className='w-full'>
                                    <p className='text-sm font-bold w-full'>
                                        Сумма ($): {modal.values.price_in_dollars}
                                    </p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold w-full'>
                                        Сумма (сом): {modal.values.price_in_soms}
                                    </p>
                                </div>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='number'
                                    placeholder={'Скидка'}
                                    required={true}
                                    label={'Скидка (сом)'}
                                    value={modal.values.discount}
                                    onChange={(event) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                discount: CheckForPositiveNumbers(event.target.value)
                                            }
                                        })
                                    }}
                                />
                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Способ оплаты:'
                                    label='Способ оплаты:'
                                    required={true}
                                    value={modal.values.payment}
                                    onChange={(event) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                payment: CheckForPositiveNumbers(event.target.value)
                                            }
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!payments.loading && !payments.error && payments.result?.data.map((payment: any, i: number) => (
                                        <option key={i} value={payment.id}>{payment.name}</option>
                                    ))}
                                </CustomSelect>
                                <div className='w-full'>
                                    <p className='text-sm font-bold w-full'>
                                        Итого к оплате (сом): {parseInt(modal.values.price_in_soms) - parseInt(modal.values.discount)} сом
                                    </p>
                                </div>
                                <div className='w-full h-full flex justify-start items-center'>
                                    <label htmlFor="allUserSelectForNotifications"
                                           className='w-full flex justify-start items-end gap-4'>
                                        <input className='w-6 h-6' type="checkbox"
                                               id='allUserSelectForNotifications'
                                               checked={modal.values.for_delivery}
                                               onChange={() => {
                                                   setModal({
                                                       ...modal,
                                                       values:{
                                                           ...modal.values,
                                                           for_delivery: !modal.values.for_delivery,
                                                       }
                                                   })
                                               }}
                                        />
                                        <span>На доставку</span>
                                    </label>
                                </div>
                            </div>
                        </>
                    }
                />
            }
        </div>
    )
}