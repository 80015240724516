import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams, RemoveEmptyObj} from "../helpers/helpers";
import { ClientUpdateI} from "../models/ClientI";

export const ClientService = {
    GetClientList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/clients/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetClientListForSearch(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/client/search/by/code/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async UpdateClient(client: ClientUpdateI) {
        return await $axios.put('/admin/client/' + `${client.id}/`, RemoveEmptyObj(client))
    },
    async CreateClient(client: ClientUpdateI) {
        return await $axios.post('/admin/client/create/', client)
    },
    async DeleteClient(client: ClientUpdateI) {
        return await $axios.delete('/admin/client/' + `${client.id}/`)
    },
}