import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput,
    CustomPageSizeInput,
    CustomSelect,
    CustomTable,
    FilterSearchIcon,
    TableRowDeleteIcon,
    TableRowEditIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers, checkModalResponse} from "../helpers/helpers";
import {SortingService} from "../services/SortingService";
import moment from "moment/moment";
import {CountriesService} from "../services/CountriesService";
import {TransportsService} from "../services/TransportsService";
import {useNavigate} from "react-router-dom";

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}
const modalInitialValues = {
    values: {
        id: '',
        tracking_number: '',
        sending_country: '',
        type_of_transport: '',
        code: '',
        full_name: '',
        comment: '',
        total_weight: '',
    },
    validation: {
        error: {
            tracking_number: false,
            sending_country: false,
            type_of_transport: false,
            code: false,
            full_name: false,
            comment: false,
            total_weight: false,
        },
        message: {
            tracking_number: '',
            sending_country: '',
            type_of_transport: '',
            code: '',
            full_name: '',
            comment: '',
            total_weight: '',
        }
    },
    type: '',
    requestIsSend: false,
    open: false,
}

export default function Sorting() {
    const navigate = useNavigate()
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {
                    field: 'code', headerName: 'Код клиента', width: 100, renderCell: (params: any) => (
                        params.user?.code
                    )
                },
                {
                    field: 'full_name', headerName: 'ФИО', width: 150, renderCell: (params: any) => (
                        params.user?.full_name
                    )
                },
                {field: 'tracking_number', headerName: 'Трек-номер', width: 150},
                {field: 'created_at', headerName: 'Дата', width: 150, renderCell:(params:any)=>(
                        moment(params.created_at).format('DD.MM.YYYY').toString()
                    )},
                {field: 'comment', headerName: 'Комментарий', width: 150},
                {field: 'actions', headerName: 'Действия', width: 80, renderCell: (params: any) => (
                        <div className='flex justify-start gap-2'>
                            <div className='tableRowIconButton' onClick={()=>{
                                setDataIntoModal(params, 'edit')
                            }}>
                                <TableRowEditIcon/>
                            </div>
                            <div className='tableRowIconButton' onClick={()=>{
                                setDataIntoModal(params, 'delete')
                            }}>
                                <TableRowDeleteIcon/>
                            </div>
                        </div>
                    )},
            ]
    })
    const [modal, setModal] = useState(modalInitialValues)

    const tableList = SortingService.GetOrderList(table.filter)
    const sendingCountries = CountriesService.GetSendingCountriesList()
    const transports = TransportsService.GetTransportsList()

    const setDataIntoModal = (params: any, type: string)=>{
        setModal({
            ...modal,
            values:{
                ...modal.values,
                ...params,
                code: params.user?.code,
                full_name: params.user?.full_name,
            },
            open: true,
            type: type
        })
    }

    useEffect(()=>{
        if(tableList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(tableList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = tableList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[tableList.loading, tableList.error])

    const submitModalForm = ()=>{
        setModal({
            ...modal,
            requestIsSend: true
        })
        switch (modal.type) {
            case 'edit':
                SortingService.UpdateOrder(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
            case 'delete':
                SortingService.DeleteOrder(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
            case 'add':
                SortingService.CreateOrder(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
            case 'send':
                SortingService.SendOrders({
                    orders: table.selectedRows,
                    total_weight: modal.values.total_weight
                }).then((res)=>{
                    window.open(res.data.sticker_link)
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
        }
    }

    const order = SortingService.SearchOrder({search: modal.values.tracking_number})

    useEffect(()=>{
        if(!order.loading && !order.error){
            if(order.result?.data.length > 0){
                const data = order.result?.data[0]
                setModal({
                    ...modal,
                    values: {
                        ...modal.values,
                        id: data.id,
                        comment: data.comment,
                        code: data.user ? data.user?.code : '',
                        full_name: data.user ? data.user.full_name : '',
                        tracking_number: data.tracking_number,
                        sending_country: data.sending_country && data.sending_country.id ? data.sending_country.id : null,
                        type_of_transport: data.type_of_transport.id,
                    }
                })
            }
            else{
                setModal({
                    ...modal,
                    values:{
                        ...modalInitialValues.values,
                        tracking_number: modal.values.tracking_number,
                    }
                })
            }
        }
    },[order.loading, order.error])

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Сортировка</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Начало даты'
                        value={table.filter.start_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    start_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Конец даты'
                        value={table.filter.end_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    end_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                </div>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event)=>{setTable({
                        ...table,
                        selectedRows: [],
                        filter:{
                            ...table.filter,
                            search: event.target.value,
                            page: 1,
                        }
                    })}}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
                <button type='button'
                        onClick={()=>{
                            setModal({...modal, open: true, type: 'add'})
                        }}
                        className='submit-button_orange flex-shrink-0 submit-button_blue h-10'>
                    Добавить посылку
                </button>
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={true}
                    selectedRowsId={table.selectedRows}
                    onSelectionModelChange={(selectionModel)=>setTable({
                        ...table,
                        selectedRows: selectionModel,
                    })}
                    onSelectAll={(params)=>params.user !== null}
                    isRowSelectDisabled={(params)=> params.user === null}
                    multipleSelect={table.filter.search !== ''}
                    onRowDoubleClick={(params)=>navigate(`/orders/${params.id}`)}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <button className='submit-button_orange flex-shrink-0 submit-button_blue h-10'
                                    disabled={table.filter.search === ''}
                                    onClick={()=>{
                                        setModal({...modal, open: true, type: 'send'})
                                    }}
                            >
                                Отправить посылки
                            </button>
                        </div>
                    }
                />
            </div>

            {modal.open &&
                <CustomForm
                    title={modal.type === 'edit' ? 'Редактировать посылку' : modal.type === 'delete' ? `Удалить посылку ${modal.values.tracking_number}?` : modal.type === 'send' ? `Введите общий вес посылок` : 'Добавить посылку'}
                    style={{maxWidth: '844px', width:'100%'}}
                    buttonText={modal.type === 'edit' ? 'Редактировать' : modal.type === 'delete' ? `Удалить` : modal.type === 'send' ? `Отправить` : 'Добавить'}
                    cancelButton={true}
                    cancelButtonText='Отмена'
                    onClose={()=>{
                        setModal(modalInitialValues)
                    }}
                    onSubmit={()=>{
                        submitModalForm()
                    }}
                    disabled={modal.requestIsSend}
                    children={
                        (modal.type === 'edit' || modal.type === 'add') ?
                        <>
                            <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Трек код'}
                                    required={false}
                                    label={'Трек код'}
                                    helperText={modal.validation.message.tracking_number}
                                    error={modal.validation.error.tracking_number}
                                    value={modal.values.tracking_number}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                tracking_number: event.target.value,
                                            }
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'ФИО'}
                                    required={false}
                                    label={'ФИО'}
                                    helperText={modal.validation.message.full_name}
                                    error={modal.validation.error.full_name}
                                    value={modal.values.full_name}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                full_name: event.target.value,
                                            }
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='number'
                                    placeholder={'Код клиента'}
                                    required={false}
                                    label={'Код клиента'}
                                    helperText={modal.validation.message.code}
                                    error={modal.validation.error.code}
                                    value={modal.values.code}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                code: event.target.value,
                                            }
                                        })
                                    }}
                                />
                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Страна отправки:'
                                    label='Страна отправки:'
                                    helperText={modal.validation.message.sending_country}
                                    error={modal.validation.error.sending_country}
                                    required={true}
                                    value={modal.values.sending_country}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                sending_country: event.target.value,
                                            }
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!sendingCountries.loading && !sendingCountries.error && sendingCountries.result?.data.map((country: any, i:number)=>(
                                        <option key={i} value={country.id}>{country.name}</option>
                                    ))}
                                </CustomSelect>

                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Транспорт:'
                                    label='Транспорт:'
                                    helperText={modal.validation.message.type_of_transport}
                                    error={modal.validation.error.type_of_transport}
                                    required={true}
                                    value={modal.values.type_of_transport}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                type_of_transport: event.target.value,
                                            }
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!transports.loading && !transports.error && transports.result?.data.map((transport: any, i:number)=>(
                                        <option key={i} value={transport.id}>{transport.name}</option>
                                    ))}
                                </CustomSelect>
                                <CustomInput
                                    className='text-input text-input_with-border col-end-3 col-start-1'
                                    type='text'
                                    placeholder={'Комментарий'}
                                    required={false}
                                    label={'Комментарий'}
                                    helperText={modal.validation.message.comment}
                                    error={modal.validation.error.comment}
                                    value={modal.values.comment}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                comment: event.target.value,
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </>
                            :
                        (modal.type === 'send') &&
                        <>
                            <div className=' w-1/2 '>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Общий вес'}
                                    required={false}
                                    label={'Общий вес'}
                                    value={modal.values.total_weight}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                total_weight: event.target.value,
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </>
                    }
                />
            }
        </div>
    )
}