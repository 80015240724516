import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput,
    CustomPageSizeInput,
    CustomSelect,
    CustomTable,
    FilterSearchIcon,
    PasswordHiddenIcon,
    PasswordVisibleIcon,
    TableRowDeleteIcon,
    TableRowEditIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers, checkModalResponse, ValidatePhoneNumber} from "../helpers/helpers";
import moment from "moment";
import {ClientService} from "../services/ClientService";
import {CityService} from "../services/CityService";
import {RegionService} from "../services/RegionService";

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

const modalInitialValues = {
    values: {
        id: '',
        code: '',
        city: '',
        address: '',
        phone: '',
        region: '',
        full_name: '',
        email: '',
        password: '',
        confirm_password: '',
    },
    validation: {
        error: {
            id: false,
            code: false,
            city: false,
            address: false,
            phone: false,
            region: false,
            full_name: false,
            email: false,
            password: false,
            confirm_password: false,
        },
        message: {
            id: '',
            code: '',
            city: '',
            address: '',
            phone: '',
            region: '',
            full_name: '',
            email: '',
            password: '',
            confirm_password: '',
        }
    },
    type: '',
    passwordVisible: false,
    requestIsSend: false,
    open: false,
}

export default function Client() {
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {field: 'code', headerName: 'Код клиента', width: 100},
                {field: 'date_joined', headerName: 'Дата', width: 100, renderCell:(params:any)=>(
                        moment(params.date_joined).format('DD.MM.YYYY').toString()
                    )},
                {field: 'full_name', headerName: 'ФИО', width: 150},
                {field: 'email', headerName: 'Почта', width: 120},
                {field: 'phone', headerName: 'Телефона', width: 120},
                {field: 'active_orders', headerName: 'Активные посылки', width: 120},
                {field: 'actions', headerName: 'Действия', width: 80, renderCell: (params: any) => (
                    <div className='flex justify-start gap-2'>
                        <div className='tableRowIconButton' onClick={()=>{
                            setDataIntoModal(params, 'edit')
                        }}>
                            <TableRowEditIcon/>
                        </div>
                        <div className='tableRowIconButton' onClick={()=>{
                            setDataIntoModal(params, 'delete')
                        }}>
                            <TableRowDeleteIcon/>
                        </div>
                    </div>
                    )},
            ]
    })

    const [modal, setModal] = useState(modalInitialValues)

    const tableList = ClientService.GetClientList(table.filter)

    const cities = CityService.GetCitiesList()
    const regions = RegionService.GetRegionsList()

    const setDataIntoModal = (params: any, type: string)=>{
        setModal({
            ...modal,
            values:{
                ...modal.values,
                ...params,
                city: params.city?.id,
                region: params.region?.id,
            },
            open: true,
            type: type
        })
    }

    useEffect(()=>{
        if(tableList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(tableList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = tableList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[tableList.loading, tableList.error])

    const submitModal = ()=>{
        setModal({
            ...modal,
            requestIsSend: true
        })
        switch (modal.type) {
            case 'edit':
                ClientService.UpdateClient(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
            case 'delete':
                ClientService.DeleteClient(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{
                    checkModalResponse(err.response.data, setModal, modal, modalInitialValues)
                })
                break;
            case 'add':
                ClientService.CreateClient(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
        }
    }

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Клиенты</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event)=>{setTable({
                        ...table,
                        selectedRows:[],
                        filter:{
                            ...table.filter,
                            search: event.target.value,
                            page: 1,
                        }
                    })}}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
                <button type='button'
                        onClick={()=>{
                            setModal({
                                ...modal,
                                open: true,
                                type: 'add'
                            })
                        }}
                        className='submit-button_orange flex-shrink-0 submit-button_blue h-10'>
                    Добавить клиента
                </button>
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>

            {modal.open &&
                <CustomForm
                    title={modal.type === 'edit' ? 'Редактировать клиента' : modal.type === 'delete' ? `Удалить клиента ${modal.values.full_name}?` : 'Добавить клиента'}
                    style={{maxWidth: '844px', width:'100%'}}
                    buttonText={modal.type === 'edit' ? 'Редактировать' : modal.type === 'delete' ? `Удалить` : 'Добавить'}
                    cancelButton={true}
                    cancelButtonText='Отмена'
                    onClose={()=>{
                        setModal(modalInitialValues)
                    }}
                    onSubmit={()=>{
                        submitModal()
                    }}
                    disabled={modal.requestIsSend}
                    children={
                        (modal.type === 'edit' || modal.type === 'add') &&
                        <>
                            <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'ФИО'}
                                    required={true}
                                    label={'ФИО'}
                                    error={modal.validation.error.full_name}
                                    helperText={modal.validation.message.full_name}
                                    value={modal.values.full_name}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values:{
                                                ...modal.values,
                                                full_name: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='number'
                                    placeholder={'Код клиента'}
                                    required={true}
                                    label={'Код клиента'}
                                    value={modal.values.code}
                                    error={modal.validation.error.code}
                                    helperText={modal.validation.message.code}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values:{
                                                ...modal.values,
                                                code: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Адрес'}
                                    required={true}
                                    label={'Адрес'}
                                    value={modal.values.address}
                                    error={modal.validation.error.address}
                                    helperText={modal.validation.message.address}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values:{
                                                ...modal.values,
                                                address: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Телефон'}
                                    required={true}
                                    label={'Телефон'}
                                    value={modal.values.phone}
                                    error={modal.validation.error.phone}
                                    helperText={modal.validation.message.phone}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values:{
                                                ...modal.values,
                                                phone: ValidatePhoneNumber(event.target.value),
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='email'
                                    placeholder={'Почта'}
                                    required={true}
                                    label={'Почта'}
                                    helperText={modal.validation.message.email}
                                    error={modal.validation.error.email}
                                    value={modal.values.email}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values:{
                                                ...modal.values,
                                                email: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Город:'
                                    label='Город:'
                                    required={false}
                                    value={modal.values.city}
                                    error={modal.validation.error.city}
                                    helperText={modal.validation.message.city}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values:{
                                                ...modal.values,
                                                city: event.target.value,
                                            },
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!cities.loading && !cities.error && cities.result?.data.map((city: any, i:number)=>(
                                        <option key={i} value={city.id}>{city.name}</option>
                                    ))}
                                </CustomSelect>

                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Регион:'
                                    label='Регион:'
                                    required={false}
                                    value={modal.values.region}
                                    error={modal.validation.error.region}
                                    helperText={modal.validation.message.region}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values:{
                                                ...modal.values,
                                                region: event.target.value,
                                            },
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!regions.loading && !regions.error && regions.result?.data.map((region: any, i:number)=>(
                                        <option key={i} value={region.id}>{region.name}</option>
                                    ))}
                                </CustomSelect>

                                <CustomInput
                                    className='text-input text-input_with-props text-input_with-border'
                                    type={modal.passwordVisible ? 'text' : 'password'}
                                    placeholder='Пароль'
                                    label='Пароль'
                                    value={modal.values.password}
                                    helperText={modal.validation.message.password}
                                    required={modal.type === 'add'}
                                    onChange={(event)=>{setModal({
                                        ...modal,
                                        values:{
                                            ...modal.values,
                                            password: event.target.value,
                                        },
                                    })}}
                                    error={modal.validation.error.password}
                                    inputProps={
                                        <div className='text-input_icon_button' onClick={()=>{
                                            setModal({
                                                ...modal,
                                                passwordVisible: !modal.passwordVisible,
                                            })
                                        }}>
                                            {modal.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                        </div>
                                    }
                                />
                                <CustomInput
                                    className='text-input text-input_with-props text-input_with-border'
                                    type={modal.passwordVisible ? 'text' : 'password'}
                                    placeholder='Повторите пароль'
                                    label='Повторите пароль'
                                    value={modal.values.confirm_password}
                                    required={modal.type === 'add'}
                                    onChange={(event)=>{setModal({
                                        ...modal,
                                        values:{
                                            ...modal.values,
                                            confirm_password: event.target.value,
                                        },
                                    })}}
                                    helperText={modal.values.password !== modal.values.confirm_password ? 'Пароли не соответствуют!' : ''}
                                    error={modal.values.password !== modal.values.confirm_password}
                                    inputProps={
                                        <div className='text-input_icon_button' onClick={()=>{
                                            setModal({
                                                ...modal,
                                                passwordVisible: !modal.passwordVisible,
                                            })
                                        }}>
                                            {modal.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                        </div>
                                    }
                                />
                            </div>
                        </>
                    }
                />
            }
        </div>
    )
}