import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput,
    CustomPageSizeInput,
    CustomSelect,
    CustomTable,
    FilterSearchIcon,
    PasswordHiddenIcon,
    PasswordVisibleIcon,
    TableRowDeleteIcon,
    TableRowEditIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {StaffService} from "../services/StaffService";
import {CheckForPositiveNumbers, checkModalResponse} from "../helpers/helpers";
import moment from "moment";
import {CountriesService} from "../services/CountriesService";
import {RolesService} from "../services/RolesService";

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

const modalInitialValues = {
    values: {
        country: '',
        id: '',
        user:{
            date: '',
            full_name: '',
            group: '',
            email: '',
            password: '',
            confirm_password: '',
        }
    },
    validation: {
        error: {
            country: false,
            id: false,
            user:{
                date: false,
                full_name: false,
                group: false,
                email: false,
                password: false,
                confirm_password: false,
            }
        },
        message: {
            country: '',
            id: '',
            user:{
                date: '',
                full_name: '',
                group: '',
                email: '',
                password: '',
                confirm_password: '',
            }
        }
    },
    type: '',
    passwordVisible: false,
    requestIsSend: false,
    open: false,
}


export default function Staff() {
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {field: 'date', headerName: 'Дата', width: 120, renderCell:(params: any)=>(
                    moment(params.user.date_joined).format('DD.MM.YYYY').toString()
                    )},
                {field: 'full_name', headerName: 'ФИО сотрудника', width: 120, renderCell:(params: any)=>(
                        params.user.full_name
                    )},
                {field: 'group', headerName: 'Должность', width: 100, renderCell:(params: any)=>(
                        params.user.groups.length > 0 && params.user.groups[0].name
                    )},
                {field: 'country', headerName: 'Страна', width: 150, renderCell:(params: any)=>(
                        params.country.name
                    )},
                {field: 'email', headerName: 'Почта', width: 150, renderCell:(params: any)=>(
                        params.user.email
                    )},
                {field: 'actions', headerName: 'Действия', width: 80, renderCell: (params: any) => (
                    <div className='flex justify-start gap-2'>
                        <div className='tableRowIconButton' onClick={()=>{
                            setDataIntoModal(params, 'edit')
                        }}>
                            <TableRowEditIcon/>
                        </div>
                        <div className='tableRowIconButton' onClick={()=>{
                            setDataIntoModal(params, 'delete')
                        }}>
                            <TableRowDeleteIcon/>
                        </div>
                    </div>
                    )},
            ]
    })
    const [modal, setModal] = useState(modalInitialValues)

    const tableList = StaffService.GetStaffList(table.filter)

    const countries = CountriesService.GetCountriesList()
    const groups = RolesService.GetRolesList()


    const setDataIntoModal = (params: any, type: string)=>{
        setModal({
            ...modal,
            values:{
                ...modal.values,
                ...params,
                country: params.country?.id,
                user:{
                    ...params.user,
                    group: params.user.groups.length > 0 && params.user.groups[0].id,
                },
            },
            open: true,
            type: type
        })
    }

    useEffect(()=>{
        if(tableList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(tableList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = tableList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[tableList.loading, tableList.error])

    const submitModal = ()=>{
        setModal({
            ...modal,
            requestIsSend: true
        })
        switch (modal.type) {
            case 'edit':
                StaffService.UpdateStaff(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
            case 'delete':
                StaffService.DeleteStaff(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{
                    checkModalResponse(err.response.data, setModal, modal, modalInitialValues)
                })
                break;
            case 'add':
                StaffService.CreateStaff(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
        }
    }

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Сотрудники</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event)=>{setTable({
                        ...table,
                        selectedRows:[],
                        filter:{
                            ...table.filter,
                            search: event.target.value,
                            page: 1,
                        }
                    })}}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
                <button type='button'
                        onClick={()=>{
                            setModal({...modal, open: true, type: 'add'})
                        }}
                        className='submit-button_orange flex-shrink-0 submit-button_blue h-10'>
                    Добавить сотрудника
                </button>
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>

            {modal.open &&
                <CustomForm
                    title={modal.type === 'edit' ? 'Редактировать сотрудника' : modal.type === 'delete' ? `Удалить сотрудника ${modal.values.user.full_name}?` : 'Добавить сотрудника'}
                    style={{maxWidth: '844px', width:'100%'}}
                    buttonText={modal.type === 'edit' ? 'Редактировать' : modal.type === 'delete' ? `Удалить` : 'Добавить'}
                    cancelButton={true}
                    cancelButtonText='Отмена'
                    onClose={()=>{
                        setModal(modalInitialValues)
                    }}
                    onSubmit={()=>{
                        submitModal()
                    }}
                    disabled={modal.requestIsSend}
                    children={
                        (modal.type === 'edit' || modal.type === 'add') &&
                        <>
                            <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'ФИО'}
                                    required={true}
                                    label={'ФИО'}
                                    helperText={modal.validation.message.user.full_name}
                                    error={modal.validation.error.user.full_name}
                                    value={modal.values.user.full_name}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                user:{
                                                    ...modal.values.user,
                                                    full_name: event.target.value,
                                                }
                                            }
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='email'
                                    placeholder={'Почта'}
                                    required={true}
                                    label={'Почта'}
                                    helperText={modal.validation.message.user.email}
                                    error={modal.validation.error.user.email}
                                    value={modal.values.user.email}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                user:{
                                                    ...modal.values.user,
                                                    email: event.target.value,
                                                }
                                            }
                                        })
                                    }}
                                />
                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Страна:'
                                    label='Страна:'
                                    required={true}
                                    helperText={modal.validation.message.country}
                                    error={modal.validation.error.country}
                                    value={modal.values.country}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                country: event.target.value,
                                            }
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!countries.loading && !countries.error && countries.result?.data.map((country: any, i:number)=>(
                                        <option key={i} value={country.id}>{country.name}</option>
                                    ))}
                                </CustomSelect>

                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Должность:'
                                    label='Должность:'
                                    required={true}
                                    helperText={modal.validation.message.user.group}
                                    error={modal.validation.error.user.group}
                                    value={modal.values.user.group}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                user:{
                                                    ...modal.values.user,
                                                    group: event.target.value,
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!groups.loading && !groups.error && groups.result?.data.map((group: any, i:number)=>(
                                        <option key={i} value={group.id}>{group.name}</option>
                                    ))}
                                </CustomSelect>

                                <CustomInput
                                    className='text-input text-input_with-props text-input_with-border'
                                    type={modal.passwordVisible ? 'text' : 'password'}
                                    placeholder='Пароль'
                                    label='Пароль'
                                    value={modal.values.user.password}
                                    helperText={modal.validation.message.user.password}
                                    error={modal.validation.error.user.password}
                                    required={modal.type === 'add'}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                user:{
                                                    ...modal.values.user,
                                                    password: event.target.value,
                                                }
                                            }
                                        })
                                    }}
                                    inputProps={
                                        <div className='text-input_icon_button' onClick={()=>{
                                            setModal({
                                                ...modal,
                                                passwordVisible: !modal.passwordVisible,
                                            })
                                        }}>
                                            {modal.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                        </div>
                                    }
                                />
                                <CustomInput
                                    className='text-input text-input_with-props text-input_with-border'
                                    type={modal.passwordVisible ? 'text' : 'password'}
                                    placeholder='Повторите пароль'
                                    label='Повторите пароль'
                                    value={modal.values.user.confirm_password}
                                    required={modal.type === 'add'}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                user:{
                                                    ...modal.values.user,
                                                    confirm_password: event.target.value,
                                                }
                                            }
                                        })
                                    }}
                                    helperText={modal.values.user.password !== modal.values.user.confirm_password ? 'Пароли не соответствуют!' : ''}
                                    error={modal.values.user.password !== modal.values.user.confirm_password}
                                    inputProps={
                                        <div className='text-input_icon_button' onClick={()=>{
                                            setModal({
                                                ...modal,
                                                passwordVisible: !modal.passwordVisible,
                                            })
                                        }}>
                                            {modal.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                                        </div>
                                    }
                                />
                            </div>
                        </>
                    }
                />
            }
        </div>
    )
}