import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const RequestService = {
    GetRequestList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/deliveries/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async DeleteRequest(request_id: string) {
        return await $axios.delete('/admin/deliveries/' + `${request_id}/`)
    },
    async SetRequestAsRead(request_id: string) {
        return await $axios.put('/admin/deliveries/' + `${request_id}/`, {
            read: true
        })
    },
}