import React from 'react';
import ReactDOM from 'react-dom/client';
import 'rsuite/dist/rsuite.min.css';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store/store";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";

let persistor = persistStore(store);
export const {dispatch} = store
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={<>loading...</>} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
