import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const SortingService = {
    GetOrderList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/orders/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async UpdateOrder(order: any) {
        return await $axios.put('/admin/sorting/orders/' + `${order.id}/`, order)
    },
    async CreateOrder(order: any) {
        return await $axios.put('/admin/sorting/orders/' + `${order.id}/`, order)
    },
    async DeleteOrder(order: any) {
        return await $axios.delete('/admin/sorting/order/delete/', {data:{order: order.id}})
    },
    SearchOrder(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/order/search/' + CreateCleanSearchParams(searchParamsObj));
        }, [CreateCleanSearchParams(searchParamsObj)])
    },
    SearchClient(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/user/search/' + CreateCleanSearchParams(searchParamsObj));
        }, [CreateCleanSearchParams(searchParamsObj)])
    },
    async SendOrders(data: any) {
        return await $axios.post('/admin/sorting/orders/send/', data)
    },
}