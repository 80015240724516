import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const NotificationsService = {
    async CreateNotification(notificationInfo: any) {
        return await $axios.post('/admin/notifications/', {...notificationInfo})
    },
    async DeleteSelectedNotifications(arrOfId: []) {
        return await $axios.post('/admin/notifications/bulk/update/delete/', {
            notification_ids: arrOfId,
            flag: 'delete',
        })
    },
    async DeleteNotification(id: string) {
        return await $axios.delete('/admin/notifications/' + `${id}/`)
    },
    GetNotificationsList(searchParams?: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/notifications/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetNotification(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get('/admin/notifications/' + `${id}`);
        }, [id])
    }
}