import React, {useState} from "react";
import {CustomInput, FilterSearchIcon} from "../helpers/common";
import {IssueService} from "../services/IssueService";
import moment from "moment/moment";

export default function Issue() {

    const [cheque, setCheque] = useState('')

    const transaction = IssueService.SearchTransaction({search: cheque})

    const [disableButton, setDisableButton] = useState<any>(false)

    const submitForm = () => {
        setDisableButton(true)
        IssueService.Issue(cheque).then((res) => {
            setDisableButton(false)
            setCheque('')
        })
    }
    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Выдача</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={cheque}
                    onChange={(event)=>{setCheque(event.target.value)}}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>
            {
                cheque === ''
                ? 'Отсканируйте бар-код...'
                    : (!transaction.loading && !transaction.error) && transaction.result?.data.length > 0
                        ?
                        transaction.result?.data.map((item:any, index:number)=>(
                            <div className='w-full flex flex-col justify-start items-end gap-7' key={index}>
                                <div className='cheque'>
                                    <div className="cheque__barcodeBox">
                                        <h1 className="cheque__code">Код G-{item.orders_group.user.code}</h1>
                                    </div>
                                    <div className="cheque__details">
                                        <div><b>Способ оплаты:</b> <span>{item.payment_method.name}</span></div>
                                        <div><b>Количество посылок:</b> <span>{item.orders_group.orders_count}</span></div>
                                        <div><b>Стоимость:</b> <span>{item.amount} сом</span></div>
                                        <div><b>Общий вес:</b> <span>{item.orders_group.total_weight} кг</span></div>
                                        <div><b>Скидка:</b> <span>{item.discount} сом</span></div>
                                        <div><b>Дата:</b> <span>{moment(item.created_at).format('DD.MM.YYYY').toString()}</span></div>
                                    </div>
                                    <div className="cheque__totalPrice">
                                        <p><b>Итоговая сумма: </b>{item.total_amount} сом</p>
                                    </div>
                                    <table className='cheque__table'>
                                        <thead>
                                        <tr>
                                            <td>Трек номер</td>
                                            <td>Количество</td>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr>
                                            <td>420982733049692365026</td>
                                            <td>150</td>
                                        </tr>
                                        <tr>
                                            <td>420982733049692365026</td>
                                            <td>150</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <button className='submit-button_orange flex-shrink-0 submit-button_blue h-10 mt-5'
                                        onClick={submitForm}
                                        type='button'
                                        disabled={disableButton}
                                >
                                    {disableButton
                                        ? <div className="loader"></div>
                                        : <span>Выдать</span>
                                    }
                                </button>
                            </div>
                        ))
                        : 'Транзакция не найдена...'
            }
        </div>
    )
}