import {createSlice} from '@reduxjs/toolkit';
import {removeCookie, setCookie} from 'typescript-cookie';
import {access_token_name, group_name, refresh_token_name} from "../../http";
import jwtDecode from "jwt-decode";

const userInitialState: any = {
    authed: false,
    full_name: 'User',
    group: {
        id: '',
        name: 'user',
    },
    country: {
        id: '',
        name: '',
    },
};


export const userSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        logout: ()=>{
            removeCookie(access_token_name);
            removeCookie(refresh_token_name);
            setTimeout(()=>{
                window.location.reload()
            },1000)
            return userInitialState
        },
        login: (state, action)=>{
            const res = action.payload
            // Get the current time in seconds
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);

            const accessDecode: any = jwtDecode(res.data.access)
            const refreshDecode: any = jwtDecode(res.data.refresh)

            const accessExpirationInSeconds = accessDecode.exp;
            const refreshExpirationInSeconds = refreshDecode.exp;

            // Calculate the difference in seconds
            const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;
            const refreshDifferenceInSeconds = refreshExpirationInSeconds - currentTimeInSeconds;

            // Convert the difference in seconds to days
            const accessDifferenceInDays = Math.ceil(accessDifferenceInSeconds / (60 * 60 * 24));
            const refreshDifferenceInDays = Math.ceil(refreshDifferenceInSeconds / (60 * 60 * 24));

            setCookie(access_token_name, res.data.access, {expires: accessDifferenceInDays})
            setCookie(refresh_token_name, res.data.refresh, {expires: refreshDifferenceInDays})

            state.authed = true
            state.group.name = res.data.group
            state.full_name = res.data.full_name
            state.country.id = res.data.country?.id
            state.country.name = res.data.country?.name
            setTimeout(()=>{
                window.location.reload()
            },1000)
        }
    },
});

export const { logout, login } = userSlice.actions
export default userSlice.reducer

