import React, {useEffect, useState} from "react";
import {
    CustomInput, CustomPageSizeInput,
    CustomTable, FilterSearchIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../helpers/helpers";
import {DeliveriesService} from "../services/DeliveriesService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}


export default function Deliveries() {
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {
                    field: 'code', headerName: 'Код клиента', width: 100, renderCell: (params: any) => (
                        params.orders_group.user?.code
                    )
                },
                {
                    field: 'full_name', headerName: 'ФИО', width: 150, renderCell: (params: any) => (
                        params.orders_group.user?.full_name
                    )
                },
                {field: 'orders_count', headerName: 'Количество', width: 150, renderCell: (params: any) => (
                        params.orders_group.orders_count
                    )},
                {field: 'total_weight', headerName: 'Вес', width: 80, renderCell: (params: any) => (
                        params.orders_group.total_weight
                    )},
                {
                    field: 'action', headerName: 'Действия', width: 80, renderCell: (params: any) => (
                        <div className='tableRowIconButton' onClick={()=>{
                            DeliveriesService.SubmitDelivery(params.id).then(()=>(
                                setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
                            ))
                        }}>
                            <CheckCircleIcon/>
                        </div>
                    )
                },
            ]
    })

    const tableList = DeliveriesService.GetGroupsList(table.filter)

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                loading: true,
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        } else {
            const data = tableList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    }, [tableList.loading, tableList.error])

    return (
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Доставки</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event) => {
                        setTable({
                            ...table,
                            selectedRows: [],
                            filter: {
                                ...table.filter,
                                search: event.target.value,
                                page: 1,
                            }
                        })
                    }}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event, value) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}