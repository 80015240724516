import React, {useEffect, useState} from "react";
import {
    CustomForm, CustomInput, CustomPageSizeInput,
    CustomTable, FilterSearchIcon, TableRowDeleteIcon,
} from "../helpers/common";
import {Autocomplete, Pagination, TextField} from "@mui/material";
import {CheckForPositiveNumbers} from "../helpers/helpers";
import {NotificationsService} from "../services/NotificationsService";
import moment from "moment/moment";
import parse from 'html-react-parser'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {useNavigate} from "react-router-dom";
import {ClientService} from "../services/ClientService";
import {getCookie} from "typescript-cookie";
import {group_name} from "../http";

const group: string | undefined = getCookie(group_name)

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}
const notificationInfoInitialState: any = {
    title: '',
    message: '',
    is_admin: group === 'Администратор',
    users: [],
    send_all: true,
}

const notificationValidationInitialState = {
    requestIsSend: false,
    modal_opened: false,
    type: '',
}

const modules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ],
}
const  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

export default function Notifications() {
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                { field: 'id', headerName: 'ID', width: '100px', hide: true},
                { field: 'title', headerName: 'Уведомление', width: '100%', maxWidth: '500px', renderCell: (params: any) => (
                        <p className='notificationsRow'>
                            <p style={params.read ? {color:'rgba(87,88,90,0.8)'} : {color: '#000032'}}>{params.title}</p>
                            <p >{parse(params.message)}</p>
                        </p>
                    )},
                { field: 'created_at', headerName: 'Дата', width: '110px', renderCell: (params: any) => (
                        moment(params.created_at).format('YYYY-MM-DD hh:mm')
                    )},
            ]
    })

    const [notificationInfo, setNotificationInfo] = useState<any>(notificationInfoInitialState)

    const [notificationValidation, setNotificationValidation] = useState<any>(notificationValidationInitialState)

    const notificationList = NotificationsService.GetNotificationsList(table.filter)

    const setNotificationIntoModal = (notificationInfoProps: any, type: string)=>{
        setNotificationInfo({
            ...notificationInfo,
            title: notificationInfoProps.title,
            message: notificationInfoProps.message,
        })
        setNotificationValidation({...notificationValidation, modal_opened: true, type: type})
    }

    useEffect(()=>{
        if(notificationList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(notificationList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: notificationList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = notificationList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[notificationList.loading, notificationList.error])

    const submitNotificationForm = ()=>{
        setNotificationValidation({
            ...notificationValidation,
            requestIsSend: true,
        })
        if(notificationValidation.type === 'view'){
            setNotificationValidation(notificationValidationInitialState)
            setNotificationInfo(notificationInfoInitialState)
            setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
        }
        if(notificationValidation.type === 'delete'){
            NotificationsService.DeleteSelectedNotifications(table.selectedRows).then(()=>{
                    setNotificationValidation(notificationValidationInitialState)
                    setNotificationInfo(notificationInfoInitialState)
                    setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
            })
        }
        if(notificationValidation.type === 'add'){
            NotificationsService.CreateNotification(notificationInfo).then(()=>{
                setNotificationValidation(notificationValidationInitialState)
                setNotificationInfo(notificationInfoInitialState)
                setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
            })
        }
    }

    const [userSearchName, setUserSearchName] = useState('')
    const [customers, setCustomers] = useState<any>([])
    const customersList = ClientService.GetClientListForSearch({search: userSearchName})
    useEffect(()=>{
        if(!customersList.loading && !customersList.error){
            setCustomers(customersList.result?.data)
        }
    },[customersList.loading])

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Уведомления</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Начало даты'
                        value={table.filter.start_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    start_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Конец даты'
                        value={table.filter.end_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    end_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                </div>
                <button type='button'
                        onClick={()=>{
                            setNotificationValidation({...notificationValidation, modal_opened: true, type: 'add'})
                        }}
                        className='submit-button_orange flex-shrink-0 submit-button_blue h-10'>
                    Добавить
                </button>
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={true}
                    selectedRowsId={table.selectedRows}
                    onSelectionModelChange={(selectionModel)=>setTable({
                        ...table,
                        selectedRows: selectionModel,
                    })}
                    onSelectAll={(params)=>params.user !== null}
                    isRowSelectDisabled={(params)=> params.user === null}
                    multipleSelect={table.filter.search !== ''}
                    onRowDoubleClick={(params)=> setNotificationIntoModal(params, 'view')}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <button className='submit-button_orange flex-shrink-0 submit-button_blue h-10'
                                    disabled={table.selectedRows.length === 0}
                                    onClick={()=>{
                                        setNotificationValidation({...notificationValidation, modal_opened: true, type: 'delete'})
                                    }}
                            >
                                Удалить
                            </button>
                        </div>
                    }
                />
            </div>

            {notificationValidation.modal_opened &&
                <CustomForm
                    title={notificationValidation.type === 'delete' ? `Удалить выбранные уведомления?` : notificationValidation.type === 'view' ? 'Просмотр уведомления' : 'Добавить уведомление'}
                    style={{maxWidth: '844px', width:'100%'}}
                    buttonText={notificationValidation.type === 'delete' ? `Удалить` : notificationValidation.type === 'view' ? 'Закрыть' : 'Добавить'}
                    cancelButton={notificationValidation.type !== 'view'}
                    cancelButtonText='Отмена'
                    onClose={()=>{
                        setNotificationValidation(notificationValidationInitialState)
                        setNotificationInfo(notificationInfoInitialState)
                    }}
                    onSubmit={()=>{
                        if (notificationInfo.message.length === 0) {
                            alert('Заполните поле с комментарием')
                            return;
                        }
                        submitNotificationForm()
                    }}
                    disabled={notificationValidation.requestIsSend}
                    requestIsSend={notificationValidation.requestIsSend}
                    children={
                        <>
                            {notificationValidation.type === 'add' &&
                                <div className='notificationForm w-full flex flex-col gap-y-5'>
                                        <Autocomplete
                                            freeSolo
                                            multiple
                                            disabled={notificationInfo.send_all}
                                            isOptionEqualToValue={(option: any, value) => {
                                                return option.full_name === value.full_name
                                            }}
                                            getOptionLabel={(option:any) => option.full_name}
                                            options={customers}
                                            loading={customersList.loading}
                                            onChange={(event: any, newValue: any,) => {
                                                setNotificationInfo({
                                                    ...notificationInfo,
                                                    users: newValue.map((customer: any)=> customer.id)
                                                })
                                            }}
                                            inputValue={userSearchName}
                                            onInputChange={(event, newInputValue,) => {
                                                setUserSearchName(newInputValue);
                                            }}
                                            sx={{borderRadius: 100}}
                                            renderInput={(params) =>
                                                <TextField {...params} placeholder="ФИО Пользователя" required={notificationInfo.users.length < 1 && !notificationInfo.send_all}
                                                />}
                                        />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder={'Заголовок'}
                                        required={true}
                                        label={''}
                                        value={notificationInfo.title}
                                        onChange={(event)=>{
                                            setNotificationInfo({
                                                ...notificationInfo,
                                                title: event.target.value,
                                            })
                                        }}
                                    />
                                        <div className='w-full h-full flex justify-start items-center'>
                                            <label htmlFor="allUserSelectForNotifications" className='w-full flex justify-start items-end gap-4'>
                                                <input className='w-6 h-6' type="checkbox"
                                                       id='allUserSelectForNotifications'
                                                       checked={notificationInfo.send_all}
                                                       onChange={()=>{
                                                           setNotificationInfo({
                                                               ...notificationInfo,
                                                               users: [],
                                                               send_all: !notificationInfo.send_all,
                                                           })
                                                       }}
                                                />
                                                <span>Отправить всем пользователям</span>
                                            </label>
                                        </div>
                                    <div className='w-full h-48 col-start-1 col-end-3'>
                                        <ReactQuill theme="snow"
                                                    style={{width: '100%', height: '140px'}}
                                                    value={notificationInfo.message}
                                                    onChange={(value)=>{
                                                        setNotificationInfo({
                                                            ...notificationInfo,
                                                            message:value,
                                                        })
                                                    }}
                                                    modules={modules}
                                                    formats={formats}
                                        />
                                    </div>
                                </div>
                            }
                            {notificationValidation.type === 'view' &&
                                <div className='notificationForm w-full flex flex-col gap-y-5'>
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        disabled={true}
                                        placeholder={'Заголовок'}
                                        required={true}
                                        label={''}
                                        value={notificationInfo.title}
                                        onChange={(event)=>{
                                            setNotificationInfo({
                                                ...notificationInfo,
                                                title: event.target.value,
                                            })
                                        }}
                                    />
                                    <div className='w-full h-48 col-start-1 col-end-3'>
                                        <ReactQuill theme="snow"
                                                    readOnly={true}
                                                    style={{width: '100%', height: '140px'}}
                                                    value={notificationInfo.message}
                                                    onChange={(value)=>{
                                                        setNotificationInfo({
                                                            ...notificationInfo,
                                                            message:value,
                                                        })
                                                    }}
                                                    modules={modules}
                                                    formats={formats}
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    }
                />
            }
        </div>
    )
}