import React, {useEffect, useState} from "react";
import {
    CustomInput,
    CustomPageSizeInput,
    CustomTable,
    FilterSearchIcon,
    ShowAllStatusIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../helpers/helpers";
import {OrdersService} from "../services/OrdersService";
import {useNavigate} from "react-router-dom";

const tableFilter = {
    search: '',
    start_date: '',
    status__status: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

export default function Orders() {
    const navigate = useNavigate()
    const [ordersTitle, setOrdersTitle] = useState('Все посылки')
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {
                    field: 'code', headerName: 'Код клиента', width: 100, renderCell: (params: any) => (
                        params.user?.code
                    )
                },
                {
                    field: 'full_name', headerName: 'ФИО', width: 150, renderCell: (params: any) => (
                        params.user?.full_name
                    )
                },
                {field: 'tracking_number', headerName: 'Трек-номер', width: 150},
                {field: 'title', headerName: 'Наименование', width: 120},
                {
                    field: 'type_of_transport', headerName: 'Транспорт', width: 120, renderCell: (params: any) => (
                        <img src={params.type_of_transport.icon} alt="icon"/>
                    )
                },
                {
                    field: 'status', headerName: 'Статус', width: 120, renderCell: (params: any) => (
                        params.status && params.status.svg_script ? (
                            <div dangerouslySetInnerHTML={{ __html: params.status.svg_script }} />
                        ) : (
                            <div>No Status Available</div>
                        )
                    )
                },
            ]
    })

    const tableList = OrdersService.GetOrders(table.filter)

    const getOrderStatuses = OrdersService.GetOrderStatusButtons()

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                loading: true,
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        } else {
            const data = tableList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    }, [tableList.loading, tableList.error])

    return (
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Посылки</h1>
            </div>

            <div className='home-page__statuses mb-7'>
                {getOrderStatuses.loading
                    ?
                    <>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                    </>
                    : !getOrderStatuses.error &&
                    <>
                        <div
                            className={`status-button ${table.filter.status__status === '' ? 'status-button_selected' : ''}`}
                            onClick={() => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        status__status: '',
                                        page: 1,
                                    }
                                })
                                setOrdersTitle('Все посылки')
                            }}>
                            <div dangerouslySetInnerHTML={{__html:
                                '<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.54 86.54"><defs><style>.cls-1:{fill:#f15a24;}</style></defs><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><polygon class="cls-1" points="63.77 80.85 63.77 86.54 0 86.54 0 22.77 5.69 22.77 5.69 80.85 63.77 80.85"/><path class="cls-1" d="M17.08,69.46V11.4H11.4V75.15H75.15V69.46Z"/><path class="cls-1" d="M22.77,0V63.77H86.54V0Zm20,50.94V12.83L77.32,31.89Z"/></g></g></svg>'
                            }}/>
                            <div>
                                <p>Все посылки</p>
                                <span>
                                    {getOrderStatuses.result?.data.map((result: any) => result.count_of_orders).reduce((partialSum: any, a: any) => partialSum + a, 0)}
                                </span>
                            </div>
                        </div>
                        {getOrderStatuses.result?.data.map((item: any) => (
                            <div
                                className={`status-button ${table.filter.status__status === item.status ? 'status-button_selected' : ''}`}
                                id={item.id}
                                key={item.id}
                                onClick={() => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            status__status: item.status,
                                            page: 1,
                                        }
                                    })
                                    setOrdersTitle(item.name)
                                }}>
                                <div dangerouslySetInnerHTML={{__html: item.svg_script}}/>
                                <div>
                                    <p>{item.name}</p>
                                    <span>{item.count_of_orders}</span>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>


            <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                <h1 className='status__title'>{ordersTitle}</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Начало даты'
                        value={table.filter.start_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    start_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Конец даты'
                        value={table.filter.end_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    end_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                </div>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event) => {
                        setTable({
                            ...table,
                            selectedRows:[],
                            filter: {
                                ...table.filter,
                                search: event.target.value,
                                page: 1,
                            }
                        })
                    }}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    onRowDoubleClick={(params)=>navigate(`/orders/${params.id}`)}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event, value) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}