import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const WarehouseService = {
    GetOrderList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/warehouse/orders/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async UpdateOrder(order: any) {
        return await $axios.post('/admin/warehouse/order/update/', order)
    },
    async CreateOrder(order: any) {
        return await $axios.post('/admin/warehouse/order/add/', order)
    },
    async DeleteOrder(order: any) {
        return await $axios.delete('/admin/warehouse/order/delete/', {data:{order: order.id}})
    },
    SearchOrder(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/warehouse/order/search/' + CreateCleanSearchParams(searchParamsObj));
        }, [CreateCleanSearchParams(searchParamsObj)])
    },
    SearchClient(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/warehouse/user/search/' + CreateCleanSearchParams(searchParamsObj));
        }, [CreateCleanSearchParams(searchParamsObj)])
    },
    async SendOrders(orders: []) {
        return await $axios.post('/admin/warehouse/orders/send/', {orders: orders})
    },
}