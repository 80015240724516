import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const LostOrdersService = {
    GetOrderList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/lost/orders/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    async AssignOrders(data: any) {
        return await $axios.post('/admin/lost/orders/assign/', data)
    },
}