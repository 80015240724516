import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput,
    CustomPageSizeInput,
    CustomSelect,
    CustomTable,
    TableRowEditIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {TariffService} from "../services/TariffService";
import {CheckForPositiveNumbers, checkModalResponse} from "../helpers/helpers";
import {CountriesService} from "../services/CountriesService";
import {TransportsService} from "../services/TransportsService";

const tableFilter = {
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

const modalInitialValues = {
    values: {
        id: '',
        address1: '',
        address2: '',
        city: '',
        receiver: '',
        sending_country: '',
        prefix: '',
        type_of_transport: '',
        tariff: '',
        delivery_period: '',
        zip_code: '',
        phone_number: '',
        state: '',
        semt: '',
    },
    validation: {
        error: {
            address1: false,
            address2: false,
            city: false,
            sending_country: false,
            prefix: false,
            receiver: false,
            type_of_transport: false,
            tariff: false,
            delivery_period: false,
            zip_code: false,
            phone_number: false,
            state: false,
            semt: false,
        },
        message: {
            address1: '',
            address2: '',
            city: '',
            sending_country: '',
            prefix: '',
            receiver: '',
            type_of_transport: '',
            tariff: '',
            delivery_period: '',
            zip_code: '',
            phone_number: '',
            state: '',
            semt: '',
        }
    },
    type: '',
    requestIsSend: false,
    open: false,
}


export default function Tariff() {
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {field: 'sending_country', headerName: 'Страна отправки', width: 120, renderCell: (params: any)=>(
                        params.sending_country.name
                    )},
                {field: 'receiver', headerName: 'Получатель', width: 120},
                {field: 'prefix', headerName: 'Префикс', width: 120},
                {field: 'type_of_transport', headerName: 'Транспорт', width: 100, renderCell: (params: any)=>(
                        <img src={params.type_of_transport.icon} alt="icon"/>
                    )},
                {field: 'tariff', headerName: 'Тариф', width: 150},
                {field: 'actions', headerName: 'Действия', width: 80, renderCell: (params: any) => (
                    <div className='tableRowIconButton' onClick={()=>{
                        setDataIntoModal(params, 'edit')
                    }}>
                        <TableRowEditIcon/>
                    </div>
                    )},
            ]
    })
    const [modal, setModal] = useState(modalInitialValues)

    const tableList = TariffService.GetTariffList(table.filter)

    const sendingCountries = CountriesService.GetSendingCountriesList()
    const transports = TransportsService.GetTransportsList()

    const setDataIntoModal = (params: any, type: string)=>{
        setModal({
            ...modal,
            values:{
                ...modal.values,
                ...params,
                type_of_transport: params.type_of_transport?.id,
                sending_country: params.sending_country?.id,
            },
            open: true,
            type: type
        })
    }

    useEffect(()=>{
        if(tableList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(tableList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = tableList.result?.data

            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[tableList.loading, tableList.error])

    const submitModal = ()=>{
        setModal({
            ...modal,
            requestIsSend: true
        })
        switch (modal.type) {
            case 'edit':
                TariffService.UpdateTariff(modal.values).then(()=>{
                    setModal(modalInitialValues)
                    tableList.execute()
                }).catch((err)=>{checkModalResponse(err.response.data, setModal, modal, modalInitialValues)})
                break;
        }
    }
    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Тарифы</h1>
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>

            {modal.open &&
                <CustomForm
                    title={modal.type === 'edit' ? 'Редактировать тариф' : modal.type === 'delete' ? `Удалить тариф?` : 'Добавить тариф'}
                    style={{maxWidth: '844px', width:'100%'}}
                    buttonText={modal.type === 'edit' ? 'Редактировать' : modal.type === 'delete' ? `Удалить` : 'Добавить'}
                    onClose={()=>{
                        setModal(modalInitialValues)
                    }}
                    onSubmit={()=>{
                        submitModal()
                    }}
                    disabled={modal.requestIsSend}
                    children={
                        <>
                            <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Адрес 1'}
                                    required={true}
                                    label={'Адрес 1'}
                                    value={modal.values.address1}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                address1: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Адрес 2'}
                                    required={false}
                                    label={'Адрес 2'}
                                    value={modal.values.address2}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                address2: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Город'}
                                    required={false}
                                    label={'Город'}
                                    value={modal.values.city}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                city: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Получатель'}
                                    required={true}
                                    label={'Получатель'}
                                    value={modal.values.receiver}
                                    onChange={(event)=>{
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                receiver: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Префикс'}
                                    required={true}
                                    label={'Префикс'}
                                    value={modal.values.prefix}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                prefix: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Тариф'}
                                    required={true}
                                    label={'Тариф'}
                                    value={modal.values.tariff}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                tariff: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Время доставки'}
                                    required={true}
                                    label={'Время доставки'}
                                    value={modal.values.delivery_period}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                delivery_period: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Почтовый адрес'}
                                    required={true}
                                    label={'Почтовый адрес'}
                                    value={modal.values.zip_code}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                zip_code: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Номер телефона'}
                                    required={true}
                                    label={'Номер телефона'}
                                    value={modal.values.phone_number}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                phone_number: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Район'}
                                    required={true}
                                    label={'Район'}
                                    value={modal.values.state}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                state: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomInput
                                    className='text-input text-input_with-border'
                                    type='text'
                                    placeholder={'Квартал'}
                                    required={true}
                                    label={'Квартал'}
                                    value={modal.values.semt}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                semt: event.target.value,
                                            },
                                        })
                                    }}
                                />
                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Страна отправки:'
                                    label='Страна отправки:'
                                    required={true}
                                    value={modal.values.sending_country}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                sending_country: event.target.value,
                                            },
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!sendingCountries.loading && !sendingCountries.error && sendingCountries.result?.data.map((country: any, i:number)=>(
                                        <option key={i} value={country.id}>{country.name}</option>
                                    ))}
                                </CustomSelect>

                                <CustomSelect
                                    className='default-select default-select_with-border'
                                    placeholder='Транспорт:'
                                    label='Транспорт:'
                                    required={true}
                                    value={modal.values.type_of_transport}
                                    onChange={(event)=>{
                                        setModal({
                                             ...modal,
                                            values: {
                                                ...modal.values,
                                                type_of_transport: event.target.value,
                                            },
                                        })
                                    }}
                                >
                                    <option value=""></option>
                                    {!transports.loading && !transports.error && transports.result?.data.map((transport: any, i:number)=>(
                                        <option key={i} value={transport.id}>{transport.name}</option>
                                    ))}
                                </CustomSelect>

                            </div>
                        </>
                    }
                />
            }
        </div>
    )
}