import React from 'react';
import {
    DesktopLogo,
    SideBarBellIcon,
    SideBarBoxOfficeIcon,
    SideBarDeliveryHistoryIcon,
    SideBarDeliveryIcon,
    SideBarExitIcon,
    SideBarHomeIcon,
    SideBarIssueIcon,
    SideBarLostOrdersIcon,
    SideBarOrdersIcon,
    SideBarPaymentHistoryIcon,
    SideBarSortingIcon,
    SideBarStaffIcon,
    SideBarTariffIcon,
    SideBarUserIcon,
    SideBarWarehouseIcon
} from "../helpers/common";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../store/slices/userSlice";
import {accessRules} from "../helpers/MiddleWare";

function generateMenuItems(userPosition: string) {
    const menuItems = [
        { path: '/home', label: 'Главная', icon: <SideBarHomeIcon /> },
        { path: '/staff', label: 'Сотрудники', icon: <SideBarStaffIcon /> },
        { path: '/orders', label: 'Посылки', icon: <SideBarOrdersIcon /> },
        { path: '/client', label: 'Клиенты', icon: <SideBarUserIcon /> },
        { path: '/warehouse', label: 'Склад', icon: <SideBarWarehouseIcon /> },
        { path: '/sorting', label: 'Сортировка', icon: <SideBarSortingIcon /> },
        { path: '/issue', label: 'Выдача', icon: <SideBarIssueIcon /> },
        { path: '/lostOrders', label: 'Неизвестные посылки', icon: <SideBarLostOrdersIcon /> },
        { path: '/boxOffice', label: 'Касса', icon: <SideBarBoxOfficeIcon /> },
        { path: '/notifications', label: 'Уведомления', icon: <SideBarBellIcon /> },
        { path: '/tariff', label: 'Тарифы', icon: <SideBarTariffIcon /> },
        { path: '/transactions', label: 'Транзакции', icon: <SideBarPaymentHistoryIcon /> },
        { path: '/shipmentHistory', label: 'Отправки со склада', icon: <SideBarDeliveryHistoryIcon /> },
        { path: '/requests', label: 'Заявки на доставку', icon: <SideBarDeliveryIcon /> },
        { path: '/deliveries', label: 'Доставки', icon: <SideBarDeliveryIcon /> },
    ];

    const rules = accessRules

    return menuItems.filter((item: any) => Array.from(new Set(rules[item.path])).some((elem: any)=> {
        return elem.group === userPosition
    }));
}

function Header() {
    const user = useSelector((state: any) => state.user)
    const location = useLocation()
    const dispatch = useDispatch()

    const menuItems = generateMenuItems(user.group.name);
    return (
        <div className='header' id='header'>
            <div className="header__mainBar">
                <div className="header-mainBar__user">
                    <div className='header-mainBar__userIcon'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#2B5EE8" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4" cy="4" r="4" transform="matrix(-1 0 0 1 16 3)" fill="#2B5EE8" stroke="#2B5EE8" strokeWidth="1.5"/>
                            <path d="M5 16.9347C5 16.0743 5.54085 15.3068 6.35109 15.0175C10.004 13.7128 13.996 13.7128 17.6489 15.0175C18.4591 15.3068 19 16.0743 19 16.9347V18.2502C19 19.4376 17.9483 20.3498 16.7728 20.1818L15.8184 20.0455C13.2856 19.6837 10.7144 19.6837 8.18162 20.0455L7.22721 20.1818C6.0517 20.3498 5 19.4376 5 18.2502V16.9347Z" fill="#2B5EE8" stroke="" strokeWidth="1.5"/>
                        </svg>
                    </div>
                   <div className='header-mainBar__userInfo'>
                       <span>{user.group.name}</span>
                       <p>{user.full_name}</p>
                   </div>
                </div>
            </div>
            <div className="header__sideBar flex flex-col justify-start items-center pt-4 pb-32">
                <div className='header-sideBar__logo mb-12'>
                    <DesktopLogo/>
                </div>
                <div className="header-sideBar__links">
                    <div className="w-full h-full overflow-scroll">
                        {menuItems.map((item, index) => (
                            <Link
                                key={index}
                                to={item.path}
                                className={`header-sideBar__link ${
                                    location.pathname.includes(item.path) ? 'header-sideBar__link_active' : ''
                                }`}
                            >
                                <div>
                                    {item.icon}
                                    <span>{item.label}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div
                        className="header-sideBar__link header-sideBar__link_exit"
                        onClick={() => {
                            dispatch(logout());
                        }}
                    >
                        <div>
                            <SideBarExitIcon />
                            <span>Выход</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;