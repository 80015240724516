import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput,
    StatisticsBoxOfficeIcon,
    StatisticsOrderIcon,
    StatisticsUserIcon
} from "../helpers/common";
import moment from "moment";
import {AnalyticsService} from "../services/AnalyticsService";
import {CurrencyService} from "../services/CurrencyService";
import {Line} from "react-chartjs-2";
import {DateRangePicker} from "rsuite";
import {endOfDay, startOfDay, subDays} from "date-fns";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {InputCheckForNumbers} from "../helpers/helpers";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
function Home() {
    const [filterValue, setFilterValue] = React.useState<any>({
        start_date: subDays(new Date(), 29),
        end_date: endOfDay(new Date()),
        flag: 'D'
    })
    const Ranges:any = [
        {
            label: 'Последние 30 дней',
            value: [subDays(new Date(), 29), endOfDay(new Date())],
            placement: 'left'
        },
        {
            label: 'Последние 7 дней',
            value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
            placement: 'left'
        }
    ];
    useEffect(()=>{
        let x = moment(filterValue.end_date);
        let y = moment(filterValue.start_date)

        let diffInDays = x.diff(y, 'days');

        if(diffInDays < 28){
            setFilterValue({
                ...filterValue,
                flag: 'D'
            })
        }
        if(diffInDays > 28 && diffInDays < 365){
            setFilterValue({
                ...filterValue,
                flag: 'M'
            })
        }
        if(diffInDays > 365){
            setFilterValue({
                ...filterValue,
                flag: 'Y'
            })
        }
    },[filterValue.start_date, filterValue.end_date])

    const [switchChart, setSwitchChart] = useState({
        chart: 'users'
    })
    const [statistics, setStatistics] = useState({
        users:{
            count: '0',
            data: []
        },
        orders: {
            count: '0',
            data: []
        },
        boxOffice: {
            count: '0',
            data: []
        }
    })

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Статистика',
            },
        },
    };
    const [labels, setLabels] = useState([])

    useEffect(()=>{
        AnalyticsService.GetAnalytics(moment(filterValue.start_date).format('YYYY-MM-DD'), moment(filterValue.end_date).format('YYYY-MM-DD'), filterValue.flag).then((res)=>{
            setLabels(res.data.labels.map((label: any)=>
                moment(label).format(`${filterValue.flag === 'Y' ? 'YYYY' : filterValue.flag === 'M' ? 'MM' : 'DD'}`).toString()
            ))
            setStatistics({
                ...statistics,
                users:{
                    count: res.data.total_users_count,
                    data: res.data.chart_data_users.map((users: any)=> users)
                },
                orders: {
                    count: res.data.orders_count,
                    data: res.data.chart_data_orders.map((orders: any)=> orders)
                },
                boxOffice: {
                    count: res.data.total_cash_amount,
                    data: res.data.chart_data_cash.map((cash: any)=> cash)
                }
            })
        })
    }, [filterValue])

    const data = {
        labels,
        datasets: [
            {
                label: `${switchChart.chart === 'users' ? 'Пользователи' : switchChart.chart === 'orders' ? 'Посылки' : 'Касса'}`,
                data: labels.map((label, index) => statistics[switchChart.chart as keyof typeof statistics].data[index]),
                borderColor: 'rgba(0, 97, 194, 0.9)',
                backgroundColor: 'rgba(0, 97, 194, 0.5)',
            },
        ],
    };


    //CURRENCY

    const [currencyValidation, setCurrencyValidation] = useState({
        requestIsSend: false,
        modal_opened: false,
        updateRequest: false,
    })
    const [usd, setUsd] = useState({
        id: '',
        name: '',
        value: '0',
        code: 'USD'
    })
    const [cny, setCny] = useState({
        id: '',
        name: '',
        value: '0',
        code: 'CNY'
    })
    const submitCurrencyChange = ()=>{
        setCurrencyValidation({
            ...currencyValidation,
            requestIsSend: true,
        })
        CurrencyService.UpdateCurrency(cny).then(()=>{
            setCurrencyValidation({
                ...currencyValidation,
                updateRequest: !currencyValidation.updateRequest,
                requestIsSend: false,
                modal_opened: false,
            })
        })
        CurrencyService.UpdateCurrency(usd).then(()=>{
            setCurrencyValidation({
                ...currencyValidation,
                updateRequest: !currencyValidation.updateRequest,
                requestIsSend: false,
                modal_opened: false,
            })
        })
    }
    const currencies = CurrencyService.GetCurrencies(currencyValidation.updateRequest)

    useEffect(()=>{
        if(!currencies.loading && !currencies.error){
            for (let i = 0; i < currencies.result?.data.length; i++){
                if(currencies.result?.data[i].code === 'USD'){
                    setUsd({
                        ...usd,
                        id: currencies.result?.data[i].id,
                        name: currencies.result?.data[i].name,
                        value: currencies.result?.data[i].value,
                        code: currencies.result?.data[i].code
                    })
                }
                if(currencies.result?.data[i].code === 'CNY'){
                    setCny({
                        ...cny,
                        id: currencies.result?.data[i].id,
                        name: currencies.result?.data[i].name,
                        value: currencies.result?.data[i].value,
                        code: currencies.result?.data[i].code
                    })
                }
            }
        }
    },[currencies.loading, currencies.error])

    return(
        <div className='componentMainWrapper'>
            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Главная</h1>
            </div>
            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <div className={`statisticsButton ${switchChart.chart === 'users' ? 'statisticsButton_selected' : ''}`}
                     onClick={()=>{setSwitchChart({...switchChart, chart: 'users'})}}
                >
                    <StatisticsUserIcon/>
                    <div className="statisticsButton__text">
                        <span>Все пользователи</span>
                        <p>{statistics.users.count}</p>
                    </div>
                </div>
                <div className={`statisticsButton ${switchChart.chart === 'orders' ? 'statisticsButton_selected' : ''}`}
                     onClick={()=>{setSwitchChart({...switchChart, chart: 'orders'})}}
                >
                    <StatisticsOrderIcon/>
                    <div className="statisticsButton__text">
                        <span>Все посылки</span>
                        <p>{statistics.orders.count}</p>
                    </div>
                </div>
                <div className={`statisticsButton ${switchChart.chart === 'boxOffice' ? 'statisticsButton_selected' : ''}`}
                     onClick={()=>{setSwitchChart({...switchChart, chart: 'boxOffice'})}}
                >
                    <StatisticsBoxOfficeIcon/>
                    <div className="statisticsButton__text">
                        <span>Общая касса</span>
                        <p>{statistics.boxOffice.count}</p>
                    </div>
                </div>
                <DateRangePicker
                    placeholder="Выбрать дату"
                    placement={'bottomEnd'}
                    showOneCalendar
                    value={(filterValue.start_date === null || filterValue.end_date === null) ? null : [filterValue.start_date, filterValue.end_date]}
                    onChange={(value)=>{
                        if(value !== null){
                            const start = moment(value[0]).format('YYYY-MM-DD').toString()
                            const end = moment(value[1]).format('YYYY-MM-DD').toString()
                            if(start === end){
                                setFilterValue({
                                    ...filterValue,
                                    start_date: null,
                                    end_date: null
                                })
                            }
                            else{
                                setFilterValue({
                                    ...filterValue,
                                    start_date: value[0],
                                    end_date: value[1]
                                })
                            }
                        }
                        else{
                            setFilterValue({
                                ...filterValue,
                                start_date: null,
                                end_date: null
                            })
                        }
                    }}
                    ranges={Ranges}
                />
            </div>
            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <div className="currencyBox">
                    <h1>Курс валют</h1>
                    <div className='w-full flex justify-between items-start gap-4'>
                        <div>
                            <span>{cny.value} сом</span>
                            <p>Юань</p>
                        </div>
                        <div>
                            <span>{usd.value} сом</span>
                            <p>Доллар</p>
                        </div>
                    </div>
                    <button
                        onClick={()=>{
                            setCurrencyValidation({
                                ...currencyValidation,
                                modal_opened: true,
                            })
                        }}
                    >
                        Редактировать
                    </button>
                </div>
                <div className='statistic__chart bg-white shadow-default rounded-10px w-full p-5 '>
                    <Line options={options} data={data} />
                </div>
            </div>

            {currencyValidation.modal_opened &&
                <CustomForm
                    title={'Редактирование курса'}
                    style={{maxWidth: '444px', width:'100%'}}
                    buttonText={'Редактировать'}
                    onClose={()=>{
                        setCurrencyValidation({
                            ...currencyValidation,
                            requestIsSend: false,
                            modal_opened: false,
                            updateRequest: !currencyValidation.updateRequest
                        })
                    }}
                    onSubmit={()=>{
                        submitCurrencyChange();
                        setCurrencyValidation({
                            ...currencyValidation,
                            requestIsSend: false,
                            modal_opened: false,
                            updateRequest: !currencyValidation.updateRequest
                        })
                    }}
                    disabled={currencyValidation.requestIsSend}
                    children={
                        <>
                            <CustomInput
                                className='text-input text-input_with-border'
                                type='text'
                                placeholder={usd.code}
                                required={false}
                                label={usd.code}
                                value={usd.value}
                                onChange={(event)=>{
                                    setUsd({
                                        ...usd,
                                        value: InputCheckForNumbers(event.target.value),
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input text-input_with-border'
                                type='text'
                                placeholder={cny.code}
                                required={false}
                                label={cny.code}
                                value={cny.value}
                                onChange={(event)=>{
                                    setCny({
                                        ...cny,
                                        value: InputCheckForNumbers(event.target.value),
                                    })
                                }}
                            />
                        </>
                    }
                />
            }
        </div>
    )
}
export default Home