import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import AuthPage from "./pages/Auth";
import Home from "./pages/Home";
import Tariff from "./pages/Tariff";
import Staff from "./pages/Staff";
import Client from "./pages/Client";
import Orders from "./pages/Orders";
import Warehouse from "./pages/Warehouse";
import Sorting from "./pages/Sorting";
import LostOrders from "./pages/LostOrders";
import ShipmentHistory from "./pages/ShipmentHistory";
import ShipmentHistoryOrders from "./pages/ShipmentHistoryOrders";
import BoxOffice from "./pages/BoxOffice";
import TransactionsDetails from "./pages/TransactionsDetails";
import Transactions from "./pages/Transactions";
import BoxOfficeDetails from "./pages/BoxOfficeDetails";
import OrderDetails from "./pages/OrderDetails";
import Issue from "./pages/Issue";
import Notifications from "./pages/Notifications";
import RequestsForDelivery from "./pages/RequestsForDelivery";
import Deliveries from "./pages/Deliveries";
import MiddleWare from "./helpers/MiddleWare";
import {useSelector} from "react-redux";
import HomePageForOthers from "./pages/HomePageForOthers";


function App() {
    const user = useSelector((state:any) => state.user)
  return (
      <BrowserRouter>
          <Routes>
              <Route path='/*' element={
                  <MiddleWare>
                    <Layout/>
                  </MiddleWare>
              }>
                  <Route index path='home' element={
                      user.group.name === 'Супер Администратор'
                          ? <Home/>
                          : <HomePageForOthers/>
                  }/>
                  <Route path='tariff' element={<Tariff/>}/>
                  <Route path='staff' element={<Staff/>}/>
                  <Route path='client' element={<Client/>}/>
                  <Route path='orders' element={<Orders/>}/>
                  <Route path='orders/:id' element={<OrderDetails/>}/>
                  <Route path='warehouse' element={<Warehouse/>}/>
                  <Route path='sorting' element={<Sorting />} />
                  <Route path='lostOrders' element={<LostOrders />} />
                  <Route path='boxOffice' element={<BoxOffice />} />
                  <Route path='boxOffice/:id' element={<BoxOfficeDetails />} />
                  <Route path='shipmentHistory' element={<ShipmentHistory />} />
                  <Route path='shipmentHistory/:id' element={<ShipmentHistoryOrders />} />
                  <Route path='transactions' element={<Transactions />} />
                  <Route path='transactions/:id' element={<TransactionsDetails />} />
                  <Route path='issue' element={<Issue />} />
                  <Route path='notifications' element={<Notifications />} />
                  <Route path='requests' element={<RequestsForDelivery />} />
                  <Route path='deliveries' element={<Deliveries />} />
              </Route>

              <Route path='login' element={
                <MiddleWare>
                  <AuthPage/>
                </MiddleWare>
              }/>
            </Routes>

      </BrowserRouter>
  );
}

export default App;
