import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const OrdersService = {
    GetOrderStatusButtons() {
        return useAsync(async () => {
            return await $axios.get('/admin/statuses/')
        }, [])
    },
    GetOrders(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/orders/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetOrder(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get('/admin/orders/' + `${id}/`);
        }, [id])
    },
}