import React, {useEffect, useState} from "react";
import {
    CustomInput, CustomPageSizeInput,
    CustomTable, FilterSearchIcon, RollBackIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../helpers/helpers";
import {ShipmentService} from "../services/ShipmentService";
import {useNavigate, useParams} from "react-router-dom";

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

export default function ShipmentHistoryOrders() {
    const {id} = useParams()
    const navigate = useNavigate();

    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {
                    field: 'code', headerName: 'Код клиента', width: 100, renderCell: (params: any) => (
                        params.user?.code
                    )
                },
                {
                    field: 'full_name', headerName: 'ФИО', width: 150, renderCell: (params: any) => (
                        params.user?.full_name
                    )
                },
                {field: 'tracking_number', headerName: 'Трек-номер', width: 150},
                {field: 'title', headerName: 'Наименование', width: 120},
                {
                    field: 'type_of_transport', headerName: 'Транспорт', width: 120, renderCell: (params: any) => (
                        <img src={params.type_of_transport.icon} alt="icon"/>
                    )
                },
                {
                    field: 'status', headerName: 'Статус', width: 120, renderCell: (params: any) => (
                        params.status && params.status.svg_script ? (
                            <div dangerouslySetInnerHTML={{ __html: params.status.svg_script }} />
                        ) : (
                            <div>No Status Available</div>
                        )
                    )
                },
            ]
    })

    const tableList = ShipmentService.GetOrderList(id)


    useEffect(()=>{
        if(tableList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(tableList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = tableList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[tableList.loading, tableList.error])

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Посылки отправленные со склада</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-2'>
                    <div className='page__rollBack-icon' onClick={()=>{navigate(-1)}}>
                        <div>
                            <RollBackIcon/>
                        </div>
                        <p>Назад</p>
                    </div>
                </div>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event)=>{setTable({
                        ...table,
                        selectedRows: [],
                        filter:{
                            ...table.filter,
                            search: event.target.value,
                            page: 1,
                        }
                    })}}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    selectedRowsId={table.selectedRows}
                    onSelectionModelChange={(selectionModel)=>setTable({
                        ...table,
                        selectedRows: selectionModel,
                    })}
                    onRowDoubleClick={(params)=>navigate(`/orders/${params.id}`)}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}