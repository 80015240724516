import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const ShipmentService = {
    GetHistoryList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/shipment/history/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetOrderList(id: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/shipment/history/' + `${id}/orders/`)
        }, [CreateCleanSearchParams(id)])
    },
}