import React, {useEffect, useState} from "react";
import {
    CustomInput, CustomPageSizeInput, CustomSelect,
    CustomTable, FilterSearchIcon,
} from "../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../helpers/helpers";
import {ShipmentService} from "../services/ShipmentService";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {CountriesService} from "../services/CountriesService";

const tableFilter = {
    search: '',
    sending_country: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}

export default function ShipmentHistory() {
    const navigate = useNavigate()

    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {
                    field: 'created_at', headerName: 'Дата', width: 150, renderCell: (params: any) => (
                        moment(params.created_at).format('DD.MM.YYYY').toString()
                    )
                },
                {field: 'orders_count', headerName: 'Количество', width: 150},
            ]
    })

    const tableList = ShipmentService.GetHistoryList(table.filter)
    const sendingCountries = CountriesService.GetSendingCountriesList()

    useEffect(()=>{
        if(!sendingCountries.loading && !sendingCountries.error){
            const data = sendingCountries.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    sending_country: data[data.length - 1].id
                },
            }))
        }
    },[sendingCountries.loading, sendingCountries.error])

    useEffect(()=>{
        if(tableList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(tableList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: tableList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = tableList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[tableList.loading, tableList.error])

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Отправки со склада</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Начало даты'
                        value={table.filter.start_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    start_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Конец даты'
                        value={table.filter.end_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    end_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                    <CustomSelect
                        className='filter-select'
                        placeholder='Страна отправитель:'
                        required={false}
                        value={table.filter.sending_country}
                        onChange={(event)=>{
                            setTable({
                                ...table,
                                filter:{
                                    sending_country: event.target.value,
                                }
                            })
                        }}
                    >
                        {!sendingCountries.loading && !sendingCountries.error && sendingCountries.result?.data.map((city: any, i:number)=>(
                            <option key={i} value={city.id}>{city.name}</option>
                        ))}
                    </CustomSelect>
                </div>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event)=>{setTable({
                        ...table,
                        selectedRows: [],
                        filter:{
                            ...table.filter,
                            search: event.target.value,
                            page: 1,
                        }
                    })}}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    selectedRowsId={table.selectedRows}
                    onSelectionModelChange={(selectionModel)=>setTable({
                        ...table,
                        selectedRows: selectionModel,
                    })}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    onRowDoubleClick={(params: any)=>navigate(`/shipmentHistory/${params.id}/`)}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}