import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const BoxOfficeService = {
    GetGroupsList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/box-office/groups/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetGroup(id?: string) {
        return useAsync(async () => {
            return await $axios.get('/admin/box-office/groups/' + `${id}/`)
        }, [id])
    },
    GetGroupOrders(searchParams: any, id?: string) {
        return useAsync(async () => {
            return await $axios.get(`/admin/box-office/groups/${id}/orders/` + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetPaymentList() {
        return useAsync(async () => {
            return await $axios.get('/admin/box-office/payment/types/')
        }, [])
    },
    async CreateTransaction(data: any) {
        return await $axios.post('/admin/box-office/transaction/create/', data)
    },
    async CalculateCashOrders(issue_id: string) {
        return await $axios.post('/admin/box-office/calculate/', {issue_id: issue_id})
    },
    GetTransactionsList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/box-office/transactions/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetTransaction(id?: string) {
        return useAsync(async () => {
            return await $axios.get('/admin/box-office/transactions/' + `${id}/`)
        }, [id])
    },
    GetTransactionOrders(searchParams: any, id?: string) {
        return useAsync(async () => {
            return await $axios.get(`/admin/box-office/transactions/${id}/orders/` + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
}